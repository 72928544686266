import * as React from "react"
/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from 'react'
import { graphql } from 'gatsby'
import { Popover, Transition } from '@headlessui/react'
import { MenuIcon, XIcon, InboxIcon, SparklesIcon } from '@heroicons/react/outline'
import { StaticImage } from "gatsby-plugin-image"
import { Helmet } from 'react-helmet'
import Referenz from '../components/general/referenz'

import Layout from "../components/layout"
import Referenzen from '../components/home/referenzen'
import Head from '../components/referenzen/head'

const referenzen = [
    { name: '👨‍👩‍👧‍👦 S48 - Stadtteiltreff Langerfeld', href: 'https://s48.wuppertal360.app', image: '../assets/tours/socialThumbnail-s48.jpg', body: 'Ein virtueller Rundgang durch den S48 Stadtteiltreff Langerfeld. Das S48 wird ausschließlich durch Spenden finanziert und agiert als gemeinnütziger Verein.', review: 'Durch die arbeiten von Wuppertal360 haben viele Leute aus Langerfeld das erste mal von unserem Stadtteiltreff erfahren. Das spiegelt sich auch in den Gästezahlen wieder.', signature: 'Mirjam, Betreiberin S48' },
    { name: '🩸 Blutspendezentrum Wuppertal', href: 'https://blutspendezentrum-wuppertal.wuppertal360.app', image: '../assets/tours/socialThumbnail-bzw.jpg', body: 'Virtueller rundgang durch das Blutspendezentrum Wuppertal mit exclusivem Blick hinter die Kulissen.    ', review: 'Mit hilfe des virtuellen Rundgang können sich unsere potentiellen Spender bereits vorab einen Eindruck unseres Blutspendezentrums machen und auch mal hinter die Kulissen blicken. Dies soll den Spendern die Angst vor dem unbekannten nehmen.', signature: 'Brigitte Dingermann, Bereich Öffentlichkeitsarbeit' },
    // { name: 'Home', href: '/', image: '', body: '', review: '', signature: '' },


  ]

export default function Refs({data}) {
  return (
    <Layout>



        <Helmet title="Referenzen - Wuppertal360 - 360° Panoramen und virtuelle Rundgänge" />
        <Head />
        <div className="relative bg-white pt-16 pb-32 overflow-hidden">




        {data.allContentfulReferenz.nodes.map((item, index) => (
                  <Referenz key={item.id} item={item} refId={item.id} index={index} className="font-medium text-gray-500 hover:text-gray-900" />

                ))}











</div>
    </Layout>

  )
}


export const query = graphql`
query ReferenzenQuery {
  allContentfulReferenz(
    sort: {fields: contentful_id, order: ASC}
    filter: {featured: {}}
  ) {
    nodes {
      body {
        body
      }
      featured
      id
      image
      link
      signature
      title
      review {
        review
      }
    }
  }
}
`
